<template>
  <div class="fast-search">
    <proposal-slider :proposals="getProposals('header-banner')" />
    <v-container>
      <Breadcrumb :items="breadcrumb" v-if="$vuetify.breakpoint.smAndUp" />
      <CategoryTitle v-if="category" :category="category" />
      <!-- <breadcrumb v-if="$vuetify.breakpoint.smAndUp" :items="breadcrumb" /> -->
      <proposal-slider :proposals="getProposals('header-banner')" />

      <div class="search-result" v-for="q in query" :key="q">
        <SimpleProductListSlider
          :query="q"
          :paginationClass="'swiper-pagination-' + q"
        />
        <v-divider class="mb-6"></v-divider>
      </div>
    </v-container>
    <proposal-slider :proposals="getProposals('footer-banner')" />
  </div>
</template>
<script>
import SimpleProductListSlider from "@/components/product/SimpleProductListSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb";

import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    SimpleProductListSlider,
    ProposalSlider,
    CategoryTitle,
    Breadcrumb
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: []
    };
  },
  methods: {
    getQueryParams() {
      let q = this.$route.query.q;
      if (typeof q === "string") {
        this.query.push(q);
      } else {
        this.query = this.$route.query.q;
      }
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
