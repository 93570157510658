import analyticsService from "~/service/analyticsService";

export default {
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true },
    position: { type: String, required: false }
  },
  methods: {
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.proposals.length > 0) {
        analyticsService.viewPromotions(this.proposals, this.position);
      }
    }
  }
};
