<template>
  <div class="proposal-slider">
    <div v-if="title" class="d-flex justify-center">
      <h2 v-html="title" class="align-center "></h2>
    </div>
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="proposal in proposals"
        :key="proposal.id"
        v-intersect.once="handleView"
      >
        <ProposalImage :proposal="proposal" />
      </swiper-slide>
    </swiper>
    <div
      v-if="proposals.length > this.slidesPerView"
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
    ></div>
  </div>
</template>
<script>
import ProposalImage from "./ProposalImage.vue";
import proposalMixin from "~/mixins/proposal";

export default {
  name: "ProposalSlider",
  props: {
    paginationClass: { type: String, default: "swiper-pagination-banner" },
    slidesPerView: { type: Number, default: 1 }
  },
  mixins: [proposalMixin],
  components: { ProposalImage },
  data() {
    return {
      swiperOption: {
        slidesPerView: this.slidesPerView,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        breakpoints: {
          320: {
            infinite: true,
            // slidesPerView: 1,
            centeredSlides: true
          },
          600: {
            // slidesPerView: 1,
            centeredSlides: true
          },
          960: {
            slidesPerView: this.slidesPerView,
            centeredSlides: false
          }
        }
      }
    };
  }
};
</script>
